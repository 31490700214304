<template>
  <ClientOnly>
    <div v-if="Number(INFO)" class="fixed bottom-2 right-2 z-[99]">
      <CommonTooltip
        show-on-mobile
        :tooltip="
          socketConnected
            ? 'Websockets connected'
            : `Websockets disconnected (${
                socketDisconnectReason ?? 'Unknown reason'
              })`
        "
      >
        <div
          class="h-3 w-3 rounded-full"
          :class="{
            'bg-red': !socketConnected,
            'bg-success': socketConnected
          }"
        ></div>
      </CommonTooltip>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useSocketData } from '@autobid/web-socket/src/helper'
import { onMounted, watch } from 'vue'

const {
  AUTOBID_WEBSOCKET: { INFO }
} = useRuntimeConfig().public

const { socketConnected, socketDisconnectReason } = useSocketData()
const { authData } = useAutobidAuth()
const push = usePush()
const { t } = useI18n()
const unmounting = ref(false)

if (process.client) {
  watch(socketConnected, (newVal: boolean, oldVal?: boolean) => {
    if (unmounting.value || authData.value.authorizing) return

    if (newVal === false && oldVal && navigator.onLine) {
      push.warning(t('lost-connection.socket-disconnected'))
    } else if (newVal && oldVal === false) {
      push.success(t('lost-connection.socket-reconnected'))
    }
  })
}
onMounted(() => {
  window.addEventListener('beforeunload', () => {
    unmounting.value = true
  })
})
</script>
