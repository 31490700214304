<template>
  <div>
    <div v-if="page || allowEmpty" class="page">
      <slot :page-data="page" />
    </div>

    <client-only>
      <CommonDialogTerms
        v-if="isAuthed && page?.page.attributes.termsRequired"
      />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onServerPrefetch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { langsNames } from '@autobid/ui/utils/langUtils'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { SLUGS_BLACKLIST } from '@autobid/strapi-integration/constants/SLUGS_BLACKLIST'
import type {
  StrapiPage,
  StrapiPageError
} from '@autobid/strapi-integration/typescript/strapi/Page'
import { extractStrapiComponents } from '@autobid/strapi-integration/utils/content'
import { hasUserAccessToThisPage } from '@autobid/strapi-integration/utils/helpers'
import type { Redirection } from '@autobid/strapi-integration/utils/redirectionHelper'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'

type Resp = {
  data: StrapiPage[]
  redirection?: Redirection
}

interface Props {
  slug: string
  draft?: boolean
  // allow to display the slot even if the pageData is empty
  allowEmpty?: boolean
  ignoreBlacklist?: boolean
}

const emit = defineEmits(['error'])
const props = defineProps<Props>()

const { $customFetch } = useCustomFetch()
const { strapiLang: locale } = useStrapiLang()
const route = useRoute()
const slug = props.slug.startsWith('/')
  ? props.slug
  : `/${locale.value}/${props.slug}`
const { isAuthed, setPageRedirectData } = useAutobidAuth()
const { t } = useI18n()
const urlParams = route.href?.split('?')?.[1] ?? ''
const nuxtApp = useNuxtApp()

const addDefaultOgSeoData = (queryPage: StrapiPage) => {
  if (!queryPage.attributes.seo) return

  const hasOgSeoData = queryPage.attributes.seo.metaSocial.find(
    (el) => el.socialNetwork === 'Facebook'
  )

  // add og data if it's not exist
  if (!hasOgSeoData && queryPage.attributes.seo) {
    queryPage.attributes.seo.metaSocial.push({
      id: 0,
      socialNetwork: 'Facebook',
      title: queryPage.attributes.seo.metaTitle,
      description: queryPage.attributes.seo.metaDescription,
      image: queryPage.attributes.seo.metaImage
    })
  }
}

const getSortedLocales = (queryPage: StrapiPage) => {
  return [...(queryPage.attributes.localizations?.data ?? [])].sort((a, b) => {
    const aLang = langsNames[a.attributes.locale].trim()
    const bLang = langsNames[b.attributes.locale].trim()

    return aLang.localeCompare(bLang, 'en', { sensitivity: 'base' })
  })
}

const pageMiddleware = (page: Resp | null) => {
  if (!page) {
    return
  }

  if (page.redirection) {
    nuxtApp.runWithContext(() =>
      navigateTo(page.redirection.to, {
        redirectCode: page.redirection.code,
        replace: true
      })
    )

    return
  }

  if (page?.data?.length) {
    try {
      const queryPage = page.data[0]
      const hasAccess = hasUserAccessToThisPage(
        queryPage.attributes.access,
        isAuthed.value
      )

      if (!hasAccess) {
        if (isAuthed.value) {
          navigateTo(`/${locale.value}`)
        } else {
          emit('error', {
            code: 401,
            message: t('error-page.401.description')
          })
        }
        return
      }

      addDefaultOgSeoData(queryPage)

      if (queryPage.attributes.localizations?.data?.length) {
        queryPage.attributes.localizations.data = getSortedLocales(queryPage)
      }

      return {
        page: queryPage,
        components: extractStrapiComponents(
          queryPage.attributes.contentSections
        ),
        headerSections: extractStrapiComponents(
          queryPage.attributes.headerSections
        ),
        sidebarSections: extractStrapiComponents(
          queryPage.attributes.sidebarSections
        )
      }
    } catch (err) {
      emit('error', {
        code: 500,
        message: err as unknown as string
      } as StrapiPageError)
    }
  } else {
    emit('error', {
      code: 404,
      message: t('error-page.404.description')
    } as StrapiPageError)
  }
}

const { data: page, suspense } = useQuery(
  ['page', props, locale.value, slug],
  async () => {
    const lastPartOfSlug = props.slug.split('/').reverse()[0]

    if (!props.ignoreBlacklist && SLUGS_BLACKLIST.includes(lastPartOfSlug)) {
      return pageMiddleware({ data: [] }) ?? null
    }

    const raw = await $customFetch<Resp>('/api/page', {
      method: 'POST',
      body: {
        path: slug,
        locale: locale.value,
        ...(props.draft && { draft: true }),
        ...(urlParams.length && { params: urlParams })
      }
    })
    return pageMiddleware(raw) ?? null
  },
  {
    refetchOnWindowFocus: false
  }
)
onServerPrefetch(suspense)

const updatePageRedirectData = () => {
  if (!page.value) return

  const willBeAccessAfterAuthChange = hasUserAccessToThisPage(
    page.value.page.attributes.access,
    !isAuthed.value
  )

  setPageRedirectData({ redirectable: willBeAccessAfterAuthChange })
}
onMounted(() => {
  updatePageRedirectData()
})

const fetchOnClient = async () => {
  // function to await in a top level component to avoid content shifts
  if (page.value) {
    return
  }
  await suspense()
}

await fetchOnClient()
</script>
