<template>
  <div
    class="flex max-h-[85vh] w-full flex-col items-center justify-between overflow-y-auto px-3 md:flex-row md:flex-wrap md:overflow-hidden"
  >
    <div class="flex items-center justify-between">
      <div class="admin-nav flex flex-col flex-wrap items-center md:flex-row">
        <a
          :href="`${url}/admin/content-manager/collection-types/api::${singularCollection}.${singularCollection}/${page.id}?plugins[i18n][locale]=${lang}`"
          target="_blank"
          class="hover:underline"
          data-testid="cms-edit-page-link"
        >
          {{ $t('admin-nav.edit-page') }}
        </a>
        <span>
          {{ $t('admin-nav.updated-at') }}
          {{ formatDate(page.attributes.updatedAt) }}
        </span>
      </div>
    </div>
    <div>
      <CommonTooltip
        tooltip="Version of the application"
        class="inline-block cursor-default"
      >
        APP v.{{ VERSION }}
      </CommonTooltip>
      |
      <CommonTooltip
        v-if="!isLoading && data"
        tooltip="Version of the Auctions API"
        class="inline-block cursor-default"
      >
        API v.{{ data.data.branch }}
      </CommonTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiPage } from '@autobid/strapi-integration/typescript/strapi/Page'
import { formatDate } from '@autobid/ui/utils/date/formatDate'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import { useQuery } from '@tanstack/vue-query'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

type AuctionsApiVersion = {
  data: {
    started_at: string
    built_at: string
    branch: string
  }
}

interface Props {
  page: StrapiPage
}

defineProps<Props>()

const {
  VERSION,
  autobidCMS: { url, singularCollection }
} = useRuntimeConfig().public
const { strapiLang: lang } = useStrapiLang()
const { $customFetch } = useCustomFetch()

const { data, isLoading } = useQuery({
  queryKey: ['auctionsApiVersion'],

  queryFn: async () => {
    try {
      const resp = await $customFetch<AuctionsApiVersion>('/api/api-ver')

      if (!resp) return null

      return resp
    } catch (_) {
      return null
    }
  }
})
</script>

<style lang="scss" scoped>
.admin-nav {
  & > * {
    @media (min-width: 768px) {
      border-right: 1px solid #fff;
      padding-right: 10px;
      margin-right: 10px;
    }

    @media (max-width: 767px) {
      padding: 4px 0;
    }
  }
}
</style>
