<template>
  <a
    :href="href"
    class="lang-switcher-link flex items-center px-2 py-1 text-primary transition hover:bg-primary hover:text-white"
    data-testid="lang-switcher-link"
  >
    <Icon
      :name="`circle-flags:${isoCode}`"
      class="mr-2 block h-5 w-5 min-w-[20px] rounded-full border border-primary bg-cover bg-center"
    />

    {{ langsNames[lang.attributes.locale] }}
  </a>
</template>

<script lang="ts" setup>
import { removeDynamicPartOfSlug } from '@autobid/strapi-integration/utils/helpers'
import type { StrapiLocalizationData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { getIsoLang, langsNames } from '@autobid/ui/utils/langUtils'

interface Props {
  lang: StrapiLocalizationData
}

const props = defineProps<Props>()

const { fullPath, name } = useRoute()
const splittedPath = fullPath.split('/')
const isItStrapiPage = name.startsWith('slugs___')
const switchLocalePath: SwitchLocalePathFunction | null = !isItStrapiPage
  ? useSwitchLocalePath()
  : null

const customI18nLinkFunction: (locale: string) => string | null = inject(
  'customI18nLinkFunction',
  null
)

const getHref = () => {
  const queryParams = fullPath.split('?')[1] || ''
  if (customI18nLinkFunction) {
    return `${customI18nLinkFunction(
      props.lang.attributes.locale
    )}?${queryParams}`
  }
  if (switchLocalePath) {
    return switchLocalePath(props.lang.attributes.locale)
  }

  let result = `/${props.lang.attributes.locale}/`
  // the "slug" word is dynamic part of url, look at the function "getSlugFromUrl"
  const propsSlug = removeDynamicPartOfSlug(props.lang.attributes.slug)

  if (!propsSlug) {
    result += splittedPath.slice(2).join('/')
  } else if (propsSlug !== '/') {
    result += `${propsSlug}/${splittedPath.slice(3).join('/')}`
  }

  return `${result}?${queryParams}`
}
const href = getHref()
const isoCode = getIsoLang(props.lang.attributes.locale)
</script>
