<template>
  <CommonErrorBoundary>
    <template #default>
      <div class="w-full">
        <NuxtLayout>
          <div>
            <NuxtLoadingIndicator color="var(--color-primary)" />

            <NuxtPage />
          </div>
        </NuxtLayout>
      </div>

      <CommonDialogRequestError />
      <CommonStrapiPopup />
      <CommonDialogNotSupportedBrowser />
      <CommonDialogDisabledJavascript />
      <CommonMeilisearchPageBrowser />
      <CommonLoader />
      <SectionsSocketDebugger />

      <CommonNotivueInit v-if="useNotifications" />
    </template>

    <template #error="{ error }">
      <ErrorPage :error="error" />
      <CommonDialogDisabledJavascript />
    </template>
  </CommonErrorBoundary>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { ref, provide, onMounted, onBeforeUnmount } from 'vue'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { SHOULD_USE_GLOBAL_BOUND_KEY } from '@autobid/ui/constants/SHOULD_USE_GLOBAL_BOUND_KEY'
import CommonErrorBoundary from '@autobid/ui/components/common/CustomErrorBoundary.vue'
import ErrorPage from './ErrorPage.vue'

interface Props {
  useNotifications?: boolean
}

defineProps<Props>()

const { $sentrySetUser } = useNuxtApp()
const { handleOnMounted, handleOnBeforeUnmount } = useBodyStore()
const { tokenData } = useToken()
const { isAuthed, afterAuthCallback } = useAutobidAuth()
const { locale } = useI18n()
const { getSession } = useAuth()

if (isAuthed.value && process.client) {
  await getSession()
}

await afterAuthCallback(locale.value)

const setSentryUser = () => {
  if (!$sentrySetUser || !tokenData.value) return

  const { id, nickname } = tokenData.value

  $sentrySetUser({ id, username: nickname })
}
setSentryUser()

const shouldUseGlobalErrorBoundary = ref(true)
provide(SHOULD_USE_GLOBAL_BOUND_KEY, shouldUseGlobalErrorBoundary)

onMounted(() => {
  handleOnMounted()
})

onBeforeUnmount(() => {
  handleOnBeforeUnmount()
})

const ONE_TRUST_ID = useRuntimeConfig().public.ONE_TRUST_ID
useHead({
  script:
    process.server && ONE_TRUST_ID?.length
      ? [
          {
            src: `https://cdn.cookielaw.org/consent/${ONE_TRUST_ID}/OtAutoBlock.js`
          },
          {
            src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
            'data-domain-script': ONE_TRUST_ID
          },
          {
            innerHTML: 'function OptanonWrapper() {}',
            type: 'text/javascript'
          }
        ]
      : []
})
</script>
