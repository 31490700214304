<template>
  <button>
    <Icon
      :name="`circle-flags:${isoCode}`"
      class="mr-3.5 block h-9 w-9 rounded-full border-2 border-primary"
      data-testid="current-lang-icon"
    />
    <span data-testid="current-lang">
      {{ langsNames[locale] }}
    </span>
  </button>
</template>

<script lang="ts" setup>
import { inject, onBeforeMount } from 'vue'
import { getIsoLang, langsNames } from '@autobid/ui/utils/langUtils'

const config = inject(Symbol.for('FormKitConfig'))

const { locale } = useI18n()
const isoCode = getIsoLang(locale.value)

onBeforeMount(() => {
  if (config) {
    config.locale = locale.value
  }
})
</script>
