<!-- eslint-disable vue/no-v-html -->
<template>
  <Switch
    class="checkbox flex h-full w-fit items-center"
    :class="small ? 'gap-2' : 'gap-4'"
    :model-value="checked"
    :disabled="isDisabled"
    @update:model-value="handleInput"
  >
    <div
      class="flex items-center justify-center rounded-md border-2 border-primary transition hover:scale-110"
      :class="{
        'bg-primary': checked,
        'bg-white': !checked && !isDisabled,
        'h-6 w-6 min-w-[1.5rem]': small,
        'h-11 w-11 min-w-[2.75rem]': !small,
        'border-primaryDark bg-[#C1C1C1]': isDisabled
      }"
    >
      <TransitionRoot
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
        :show="checked"
        appear
      >
        <Icon
          name="material-symbols:check"
          :size="small ? '18' : '36'"
          class="text-white"
        />
      </TransitionRoot>
    </div>
    <CheckboxLabel
      class="text-ellipsis"
      :class="{
        'flex justify-between': labelSuffix !== undefined,
        '!text-[#AFAFAF]': isDisabled
      }"
      v-bind="labelProps"
    >
      <span
        class="[&>a]:underline"
        @click="handleLabelClick"
        v-html="label"
      ></span>
      <span v-if="labelSuffix !== undefined" class="pl-1">
        {{ labelSuffix }}
      </span>
    </CheckboxLabel>
  </Switch>
</template>

<script setup lang="ts">
import { Switch, TransitionRoot } from '@headlessui/vue'
import type { HTMLAttributes } from 'vue'
import CheckboxLabel from './CheckboxLabel.vue'

interface Props {
  checked: boolean
  label: string
  labelSuffix?: string | number
  small?: boolean
  disabled?: boolean
  labelProps?: HTMLAttributes
  uncheckIfDisabled?: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{ (e: 'change', value: boolean): void }>()

const isDisabled = computed(() => props.disabled)

const handleInput = () => {
  emits('change', !props.checked)
}

const handleLabelClick = (e) => {
  if (e.target.tagName.toLowerCase() !== 'a') return

  const href = e.target.getAttribute('href')

  if (!href.length) return

  window.open(href, '_blank')
}

const handleUncheckIfDisabled = () => {
  if (props.uncheckIfDisabled && isDisabled.value && props.checked) {
    emits('change', false)
  }
}

handleUncheckIfDisabled()
watch(isDisabled, () => {
  handleUncheckIfDisabled()
})
</script>
