<!-- eslint-disable vue/no-v-html -->
<template>
  <common-dialog v-if="terms?.length" :opened="terms.length ? true : false" @close="handleClose">
    <template #title>
      <p class="text-center">{{ $t('terms-modal.title') }}</p>
    </template>
    <template #content>
      <div ref="contentNode" class="w-full overflow-hidden">
        <transition
          enter-from-class="opacity-0 -translate-x-full"
          enter-active-class="duration-300"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="duration-300"
          leave-to-class="opacity-0 translate-x-full"
        >
          <div v-if="isTermVisible" class="terms-content flex flex-col gap-3">
            <div
              class="prose-sm max-w-full md:prose-base lg:prose-lg"
              v-html="terms[0].text?.terms"
            ></div>

            <ElementsCheckbox
              v-for="(checkbox, index) in terms[0].checkboxes"
              :key="checkbox.term_id"
              :checked="model[`${checkbox.term_id}${index}`]"
              :label="`${checkbox.content}*`"
              :small="true"
              @change="(newVal: boolean) => handleUpdateAcceptance(newVal, checkbox.term_id, index)"
            />

            <p v-if="error" class="text-sm text-red">{{ error }}</p>
          </div>
        </transition>
      </div>
    </template>

    <template #buttons>
      <div
        class="flex flex-col-reverse flex-wrap items-center justify-center gap-3 sm:flex-row"
      >
        <div class="w-full sm:w-auto">
          <elements-button
            error.value
            size="small"
            color="primary"
            :outline="true"
            @click="handleClose"
          >
            {{ $t('terms-modal.cancel') }}
          </elements-button>
        </div>

        <div class="relative z-10 w-full overflow-hidden sm:w-auto">
          <elements-button
            size="small"
            class="border-2 border-primary"
            @click="handleAccept"
          >
            {{ $t('terms-modal.confirm-and-continue') }}
          </elements-button>
          <span
            v-if="acceptingInProgress"
            class="absolute inset-0 z-30 flex items-center justify-center after:absolute after:inset-0 after:z-0 after:bg-black after:opacity-50"
          >
            <CommonSpinner class="relative z-10 mr-2 !h-6 !w-6" />
          </span>
        </div>
      </div>
    </template>
  </common-dialog>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useProfile } from '@autobid/ui/composables/useProfile'
import { ref, computed, nextTick } from 'vue'

const { data: profile, refetch, revalidate } = useProfile()
const { APP_ID } = useRuntimeConfig().public
const { signOut } = useAutobidAuth()
const { $customFetch } = useCustomFetch()
const model = ref<Record<number, boolean>>({})
const error = ref('')
const isTermVisible = ref(true)
const acceptingInProgress = ref(false)
const contentNode = ref<HTMLDivElement>()

const terms = computed(() =>
  (profile.value?.terms || []).filter(
    (checkbox) => !checkbox.status && checkbox.apps.includes(APP_ID)
  )
)

const { t } = useI18n()

const checkIfAllCurrentCheckboxesAreAccepted = () => {
  return terms.value[0].checkboxes.every(
    (checkbox, index) => model.value[`${checkbox.term_id}${index}`]
  )
}

const handleAccept = async () => {
  const areAllChecked = checkIfAllCurrentCheckboxesAreAccepted()

  if (!areAllChecked) {
    error.value = t('terms-modal.please-activate-the-checkbox')

    nextTick(() => {
      contentNode.value?.parentNode?.scrollTo({
        top: contentNode.value.parentNode.scrollHeight,
        behavior: 'smooth'
      })
    })
    return
  }

  error.value = ''

  acceptingInProgress.value = true
  const id = terms.value[0].id
  try {
    await $customFetch<{ status?: string }>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryMethod: 'POST',
        queryUrl: `/v1/terms/${id}/mark-as-checked`
      }
    })
  } catch (err) {
    const { message } = getError(err)
    error.value = message
  }

  await revalidate()
  await refetch()

  acceptingInProgress.value = false
}

const handleClose = () => {
  signOut()
}

const handleUpdateAcceptance = (
  value: boolean,
  termId: number,
  index: number
) => {
  model.value[`${termId}${index}`] = value

  if (checkIfAllCurrentCheckboxesAreAccepted()) {
    error.value = ''
  }
}
</script>
